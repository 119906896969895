import {
  ref,
} from '@vue/composition-api'

export default {
  const: {
    TYPE: {
      HOME_SIMPLE: 'HOME_SIMPLE',
      RECENT_PRODUCT: 'RECENT_PRODUCT',
      RECOMMEND_CAMPAIGN: 'RECOMMEND_CAMPAIGN',
      FIXED_CAMPAIGN: 'FIXED_CAMPAIGN',
    },
  },
  widget: {
    type: ref(),
    name: ref(),
    state: ref(),
    startAt: ref(),
    endAt: ref(),
    isSaving: ref(false),
  },
  homeSimple: {
    title: ref(),
    text: ref(),
    imgUrl: ref(),
    isSaving: ref(false),
  },
  recentProduct: {
    items: ref(),
    text: ref(),
    visible: ref(false),
    isSaving: ref(false),
    errorMessage: ref(),
  },
  recommendCampaign: {
    items: ref(),
    text: ref(),
    visible: ref(false),
    isSaving: ref(false),
    errorMessage: ref(),
  },
  timelines: ref([]),
}
