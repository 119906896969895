import data from './data'
import actions from './actions'
import useToase from '@/utils/toast'
import { getRouterIdx } from '@/utils/router'
import useTimelines from './useTimelines'
import useResolveValue from '@/utils/resolveValue'

export default () => {
  const { makeToast } = useToase()
  const routerIdx = getRouterIdx()
  const { fetchTimelines } = useTimelines()
  const {
    resolveUtcStartDateTime,
    resolveUtcEndDateTime,
  } = useResolveValue()
  const updateHomeSimple = () => {
    data.widget.isSaving.value = true
    actions.updateHomeSimple({
      idx: routerIdx,
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        title: data.homeSimple.title.value,
        text: data.homeSimple.text.value,
        img_url: data.homeSimple.imgUrl.value,
      },
    }).then(() => {
      fetchTimelines()
      makeToast('primary', '위젯 수정에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '위젯 수정에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const updateRecentProduct = () => {
    data.widget.isSaving.value = true
    const idxList = data.recentProduct.items.value?.map(item => item.idx) ?? []
    actions.updateRecentProduct({
      idx: routerIdx,
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
        start_at: resolveUtcStartDateTime(data.widget.startAt.value),
        end_at: resolveUtcEndDateTime(data.widget.endAt.value),
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(() => {
      fetchTimelines()
      makeToast('primary', '위젯 수정에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '위젯 수정에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const updateRecommendCampaign = () => {
    data.widget.isSaving.value = true
    const idxList = data.recommendCampaign.items.value?.map(item => item.idx) ?? []
    actions.updateRecommendCampaign({
      idx: routerIdx,
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(() => {
      fetchTimelines()
      makeToast('primary', '위젯 수정에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '위젯 수정에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const updateFixedCampaign = () => {
    data.widget.isSaving.value = true
    const idxList = data.recommendCampaign.items.value?.map(item => item.idx) ?? []
    actions.updateFixedCampaign({
      idx: routerIdx,
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(() => {
      fetchTimelines()
      makeToast('primary', '위젯 수정에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '위젯 수정에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const update = () => {
    if (data.widget.type.value === data.const.TYPE.HOME_SIMPLE) {
      updateHomeSimple()
    } else if (data.widget.type.value === data.const.TYPE.RECENT_PRODUCT) {
      updateRecentProduct()
    } else if (data.widget.type.value === data.const.TYPE.RECOMMEND_CAMPAIGN) {
      updateRecommendCampaign()
    } else if (data.widget.type.value === data.const.TYPE.FIXED_CAMPAIGN) {
      updateFixedCampaign()
    }
  }

  return {
    update,
    isSaving: data.widget.isSaving,
  }
}
