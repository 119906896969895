import axiosFrame from '@/utils/axiosFrame'

export default {
  fetchDetail: axiosFrame('get', '/manage/app-widget/detail/'),
  fetchTimelines: axiosFrame('get', '/manage/app-widget/timelines/'),
  searchProductList: axiosFrame('post', '/manage/app-widget/search-product-list/'),
  searchCampaignList: axiosFrame('post', '/manage/app-widget/search-campaign-list/'),
  updateHomeSimple: axiosFrame('post', '/manage/app-widget/update-home-simple/'),
  updateRecentProduct: axiosFrame('post', '/manage/app-widget/update-recent-product/'),
  updateRecommendCampaign: axiosFrame('post', '/manage/app-widget/update-recommend-campaign/'),
  updateFixedCampaign: axiosFrame('post', '/manage/app-widget/update-fixed-campaign/'),
}
