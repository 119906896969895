<template>
  <div>
    <BRow>
      <BCol cols="4">
        <BFormGroup label="TITLE">
          <BFormInput v-model="title" />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="TEXT">
          <BFormInput v-model="text" />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="IMG">
          <BFormFile
            v-model="file"
            accept="image/*"
            @input="inputFile"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow v-if="imgUrl != null">
      <BCol
        cols="4"
        offset="8"
      >
        <BImg
          :src="imgUrl"
          fluid
        />
      </BCol>
    </BRow>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import useHomeSimple from '../../useHomeSimple'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BImg,
  },
  setup() {
    return {
      ...useHomeSimple(),
    }
  },
})
</script>
