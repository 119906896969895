import data from './data'
import actions from './actions'
import { getRouterIdx } from '@/utils/router'
import useToast from '@/utils/toast'

export default () => {
  const routerIdx = getRouterIdx()
  const { makeToast } = useToast()
  const fetchTimelines = () => {
    actions.fetchTimelines({
      idx: routerIdx,
    }).then(response => {
      data.timelines.value = response.data.data
    }).catch(() => {
      makeToast('danger', '타임라인을 가져오는데 실패했습니다')
    })
  }

  return {
    timelines: data.timelines,
    fetchTimelines,
  }
}
