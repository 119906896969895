<template>
  <TagbyDetailLayout>
    <template #content>
      <WidgetInfo />
      <ContentInfo />
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV2 :timelines="timelines" />
    </template>

    <template #hidden>
      <UpdateRecentProductSidebar />
      <UpdateRecommendCampaignSidebar />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import WidgetInfo from './blocks/WidgetInfo'
import ContentInfo from './blocks/ContentInfo'
import ActionCard from './blocks/ActionCard'
import UpdateRecentProductSidebar from './blocks/UpdateRecentProductSidebar'
import UpdateRecommendCampaignSidebar from './blocks/UpdateRecommendCampaignSidebar'
import useFetchDetail from './useFetchDetail'
import useTimelines from './useTimelines'
import TagbyTimelinesV2 from '@/components/TagbyTimelinesV2.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    WidgetInfo,
    ContentInfo,
    ActionCard,
    UpdateRecentProductSidebar,
    UpdateRecommendCampaignSidebar,
    TagbyTimelinesV2,
  },
  setup() {
    const {
      fetchDetail,
    } = useFetchDetail()
    fetchDetail()

    const {
      timelines,
      fetchTimelines,
    } = useTimelines()
    fetchTimelines()

    return {
      timelines,
    }
  },
})
</script>
