import data from './data'
import actions from './actions'
import { getRouterIdx } from '@/utils/router'
import useToast from '@/utils/toast'

export default () => {
  const routerIdx = getRouterIdx()
  const { makeToast } = useToast()
  const fetchDetail = () => {
    actions.fetchDetail({
      idx: routerIdx,
    }).then(response => {
      const resData = response.data.data
      data.widget.type.value = resData.type
      data.widget.state.value = resData.state
      data.widget.name.value = resData.name
      data.widget.startAt.value = resData.start_at
      data.widget.endAt.value = resData.end_at
      if (resData.type === data.const.TYPE.HOME_SIMPLE) {
        data.homeSimple.title.value = resData.content.title
        data.homeSimple.text.value = resData.content.text
        data.homeSimple.imgUrl.value = resData.content.img_url
      } else if (resData.type === data.const.TYPE.RECENT_PRODUCT) {
        data.recentProduct.items.value = resData.content.items
      } else if (resData.type === data.const.TYPE.RECOMMEND_CAMPAIGN) {
        data.recommendCampaign.items.value = resData.content.items
      } else if (resData.type === data.const.TYPE.FIXED_CAMPAIGN) {
        data.recommendCampaign.items.value = resData.content.items
      }
    }).catch(() => {
      makeToast('danger', '위젯 정보를 불러오는데 실패했습니다')
    })
  }
  return {
    fetchDetail,
  }
}
